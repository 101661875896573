import { axiosDispatch } from '@/utils'
const disputesV2 = 'api/disputes/v2'
// https://backend.justto.app/api/disputes/v2/increase/proposal/67a3b951085b0547b2fddb09/RECUSADO

const actions = {
  sendFeedbackProposal({ _ }, { id, action }) {
    return axiosDispatch({
      url: `${disputesV2}/increase/proposal/${id}/${action}`
    })
  }
}

export default actions
