import Vue from 'vue'
import store from '@/store'
import * as amplitude from '@amplitude/analytics-browser'

const AMPLITUDE_API_KEY = 'b9477df19dea91fb382f82aa2277cbc'

const jusAmplitude = {
  install(Vue) {
    amplitude.init(AMPLITUDE_API_KEY, {
      defaultTracking: false,
      autocapture: {
        attribution: false,
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false
      }
    })

    Vue.prototype.$jusAmplitudeIdentify = () => {
      amplitude.setUserId(store.getters.accountEmail)
    }

    Vue.prototype.$jusAmplitude = (event, prop) => {
      const properties = {
        userId: store.getters.accountEmail,
        workspace: store.getters.workspaceName,
        team: store.getters.workspaceTeamName,
        profile: store.getters.workspaceProfile,
        source: 'front#potal-legacy',
        ...prop
      }

      amplitude.track(event, properties)
      if (process.env.NODE_ENV === 'development') {
        console.log('amplitude', event, properties)
      }
    }
  }
}

Vue.use(jusAmplitude)
