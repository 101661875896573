import { DisputeModel } from '@/models/dispute/DisputeModel'

export default function convertDisputeModelToJson(dispute) {
  const getterNames = Object.getOwnPropertyNames(DisputeModel.prototype)
    .filter(prop => prop.startsWith('getDispute'))

  const plainObject = {}
  getterNames.forEach(getter => {
    try {
      plainObject[getter] = dispute[getter]
    } catch (error) {
      console.error(`Erro ao acessar propriedade ${getter}:`, error)
    }
  })

  return plainObject
}
